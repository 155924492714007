import { TabProps } from 'components/v2/Toggle/Toggle';
import {
  DATACENTER_PROXIES_PLANS,
  DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ,
} from 'constants/plans';

// 1. DC GB Self service

const dcGbSs1 = {
  title: '50 GB',
  discount: null,
  price: '$0.60',
  priceOld: null,
  pricePer: 'GB',
  priceTotal: '$30',
  priceTotalOld: null,
  planId: DATACENTER_PROXIES_PLANS.nimble,
};

const dcGbSs2 = {
  title: '100 GB',
  discount: '8%',
  price: '$0.55',
  priceOld: '$0.60',
  pricePer: 'GB',
  priceTotal: '$55',
  priceTotalOld: '$60',
  planId: DATACENTER_PROXIES_PLANS.foxy,
};

const dcGbSs3 = {
  title: '300 GB',
  discount: '11%',
  price: '$0.53',
  priceOld: '$0.60',
  pricePer: 'GB',
  priceTotal: '$160',
  priceTotalOld: '$180',
  planId: DATACENTER_PROXIES_PLANS.clever,
  isPopular: true,
};

const dcGbSs4 = {
  title: '1000 GB',
  discount: '17%',
  price: '$0.50',
  priceOld: '$0.60',
  pricePer: 'GB',
  priceTotal: '$500',
  priceTotalOld: '$600',
  planId: DATACENTER_PROXIES_PLANS.sharp,
};

// 1. DC GB Enterprise

const dcGbEn1 = {
  title: '2000 GB',
  discount: '20%',
  price: '$0.48',
  priceOld: '$0.60',
  pricePer: 'GB',
  priceTotal: '$950',
  priceTotalOld: '$1200',
  planId: '777244',
};

const dcGbEn2 = {
  title: '3000 GB',
  discount: '22%',
  price: '$0.47',
  priceOld: '$0.60',
  pricePer: 'GB',
  priceTotal: '$1,413',
  priceTotalOld: '$1800',
  planId: '777245',
};

const dcGbEn3 = {
  title: '4000 GB',
  discount: '22%',
  price: '$0.47',
  priceOld: '$0.60',
  pricePer: 'GB',
  priceTotal: '$1,884',
  priceTotalOld: '$2400',
  planId: '777246',
};

const dcGbEn4 = {
  contactSales: true,
};

// 2. DC IP self service

const dcIpSs1 = {
  title: '100 IPs',
  discount: null,
  price: '$0.100',
  priceOld: null,
  pricePer: 'IP',
  priceTotal: '$10',
  priceTotalOld: null,
};

const dcIpSs2 = {
  title: '200 IPs',
  discount: '5%',
  price: '$0.095',
  priceOld: '$0.1',
  pricePer: 'IP',
  priceTotal: '$19',
  priceTotalOld: '$20',
};

const dcIpSs3 = {
  title: '500 IPs',
  discount: '10%',
  price: '$0.090',
  priceOld: '$0.1',
  pricePer: 'IP',
  priceTotal: '$45',
  priceTotalOld: '$50',
  isPopular: true,
};

const dcIpSs4 = {
  contactSales: true,
};

// 2. DC IP enterprise

const dcIpEn1 = {
  contactSales: true,
};

// 3. DC Dedicated self service

const dcDedicatedSeqSs1 = {
  title: '3 IPs',
  discount: null,
  price: '$2.5',
  priceOld: null,
  pricePer: 'IP',
  priceTotal: '$7.5',
  priceTotalOld: null,
};

const dcDedicatedSeqSs2 = {
  title: '20 IPs',
  discount: '20%',
  price: '$2.0',
  priceOld: '$2.5',
  pricePer: 'IP',
  priceTotal: '$40',
  priceTotalOld: '$50',
  isPopular: true,
};

const dcDedicatedSeqSs3 = {
  title: '50 IPs',
  discount: '24%',
  price: '$1.9',
  priceOld: '$2.5',
  pricePer: 'IP',
  priceTotal: '$95',
  priceTotalOld: '$125',
};

const dcDedicatedSeqSs4 = {
  title: '200 IPs',
  discount: '36%',
  price: '$1.6',
  priceOld: '$2.5',
  pricePer: 'IP',
  priceTotal: '$320',
  priceTotalOld: '$500',
};

// 3. DC Dedicated enterprise

const dcDedicatedSeqEn1 = {
  title: '500 IPs',
  discount: '40%',
  price: '$1.50',
  priceOld: '$2.5',
  pricePer: 'IP',
  priceTotal: '$750',
  priceTotalOld: '$1250',
  planId: '776893',
};

const dcDedicatedSeqEn2 = {
  title: '1000 IPs',
  discount: '42%',
  price: '$1.45',
  priceOld: '$2.5',
  pricePer: 'IP',
  priceTotal: '$1,450',
  priceTotalOld: '$2500',
  planId: '776894',
};

const dcDedicatedSeqEn3 = {
  title: '2000 IPs',
  discount: '46%',
  price: '$1.35',
  priceOld: '$2.5',
  pricePer: 'IP',
  priceTotal: '$2,700',
  priceTotalOld: '$5000',
  planId: '776895',
};

const dcDedicatedSeqEn4 = {
  title: '4000 IPs',
  discount: '52%',
  price: '$1.21',
  priceOld: '$2.5',
  pricePer: 'IP',
  priceTotal: '$4,840',
  priceTotalOld: '$10000',
  planId: '776897',
};

const dcDedicatedSeqEn5 = {
  contactSales: true,
};

// 4. DC Dedicated self service

const dcDedicatedNonSeqSs1 = {
  title: '3 IPs',
  discount: null,
  price: '$3.13',
  priceOld: null,
  pricePer: 'IP',
  priceTotal: '$9.39',
  priceTotalOld: null,
  planId: DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ.teeny,
};

const dcDedicatedNonSeqSs2 = {
  title: '20 IPs',
  discount: '20%',
  price: '$2.5',
  priceOld: '$3.13',
  pricePer: 'IP',
  priceTotal: '$50',
  priceTotalOld: '$62.6',
  planId: DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ.tiny,
};

const dcDedicatedNonSeqSs3 = {
  title: '50 IPs',
  discount: '24%',
  price: '$2.38',
  priceOld: '$3.13',
  pricePer: 'IP',
  priceTotal: '$119',
  priceTotalOld: '$156.5',
  planId: DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ.mini,
  isPopular: true,
};

const dcDedicatedNonSeqSs4 = {
  title: '200 IPs',
  discount: '36%',
  price: '$2.0',
  priceOld: '$3.13',
  pricePer: 'IP',
  priceTotal: '$400',
  priceTotalOld: '$626',
  planId: DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ.main,
};

// 4. DC Dedicated enterprise

const dcDedicatedNonSeqEn1 = {
  title: '500 IPs',
  discount: '40%',
  price: '$1.87',
  priceOld: '$3.13',
  pricePer: 'IP',
  priceTotal: '$937',
  priceTotalOld: '$1565',
  planId: '793269',
};

const dcDedicatedNonSeqEn2 = {
  title: '1000 IPs',
  discount: '42%',
  price: '$1.81',
  priceOld: '$3.13',
  pricePer: 'IP',
  priceTotal: '$1,813',
  priceTotalOld: '$3130',
  planId: '793270',
};

const dcDedicatedNonSeqEn3 = {
  title: '2000 IPs',
  discount: '46%',
  price: '$1.69',
  priceOld: '$3.13',
  pricePer: 'IP',
  priceTotal: '$3,375',
  priceTotalOld: '$6260',
  planId: '793271',
};

const dcDedicatedNonSeqEn4 = {
  title: '4000 IPs',
  discount: '52%',
  price: '$1.51',
  priceOld: '$3.13',
  pricePer: 'IP',
  priceTotal: '$6,050',
  priceTotalOld: '$12520',
  planId: '793272',
};

const dcDedicatedNonSeqEn5 = {
  contactSales: true,
};

export const dcPricingsGlobal = {
  payGb: {
    standard: [dcGbSs1, dcGbSs2, dcGbSs3, dcGbSs4],
    enterprise: [dcGbEn1, dcGbEn2, dcGbEn3, dcGbEn4],
  },
  sharedPayIp: {
    standard: [dcIpSs1, dcIpSs2, dcIpSs3, dcIpSs4],
    enterprise: [dcIpEn1],
  },
  dedicatedPayIp: {
    'sequential-standard': [
      dcDedicatedSeqSs1,
      dcDedicatedSeqSs2,
      dcDedicatedSeqSs3,
      dcDedicatedSeqSs4,
    ],
    'sequential-enterprise': [
      dcDedicatedSeqEn1,
      dcDedicatedSeqEn2,
      dcDedicatedSeqEn3,
      dcDedicatedSeqEn4,
      dcDedicatedSeqEn5,
    ],
    'non-sequential-standard': [
      dcDedicatedNonSeqSs1,
      dcDedicatedNonSeqSs2,
      dcDedicatedNonSeqSs3,
      dcDedicatedNonSeqSs4,
    ],
    'non-sequential-enterprise': [
      dcDedicatedNonSeqEn1,
      dcDedicatedNonSeqEn2,
      dcDedicatedNonSeqEn3,
      dcDedicatedNonSeqEn4,
      dcDedicatedNonSeqEn5,
    ],
  },
} as const;

export const dcPricingsChina = {
  ...dcPricingsGlobal,
} as const;

export const tabsDcChina: TabProps[] = [
  { key: 'payGb', label: '共享流量' },
  { key: 'sharedPayIp', label: '共享 IP' },
  { key: 'dedicatedPayIp', label: '独享 IP ' },
];

export const tabsDcGlobal: TabProps[] = [
  { key: 'payGb', label: 'Pay / GB' },
  { key: 'sharedPayIp', label: 'Pay / IP' },
  { key: 'dedicatedPayIp', label: 'Dedicated' },
];

const featuresDcPayGbGlobal = [
  { feature: '100K+ shared IPs' },
  { feature: '99.99% uptime' },
  { feature: 'US and EU locations' },
  { feature: '<0.3 s response time' },
  { feature: 'Rotating & static IPs' },
  { feature: 'Unlimited connections and threads' },
];

const featuresDcPayIpGlobal = [
  { feature: 'Exclusive IP pool' },
  { feature: 'SOCKS5 and HTTP(s)' },
  { feature: '8 locations worldwide' },
  { feature: 'Rotating & static IPs' },
  { feature: '<0.3 s response time' },
  { feature: 'Whitelisted IPs' },
];

const featuresDcDedicatedGlobal = [
  { feature: '400K US IPs' },
  { feature: 'Unlimited threads & GBs' },
  { feature: 'SOCKS5 and HTTP(s)' },
  { feature: '99.99% uptime' },
  { feature: 'Over 1500 subnets' },
  { feature: 'IP replacement option' },
];

export const featuresDcGlobal = {
  payGb: featuresDcPayGbGlobal,
  sharedPayIp: featuresDcPayIpGlobal,
  dedicatedPayIp: featuresDcDedicatedGlobal,
};

const featuresDcPayGbChina = [
  { feature: '100K+共享IP' },
  { feature: '99.99% 正常运行时间' },
  { feature: '美国和欧盟地点' },
  { feature: '<0.3 秒响应时间' },
  { feature: '旋转和静态 IP' },
  { feature: '无限连接和线程' },
];

const featuresDcPayIpChina = [
  { feature: '专属IP池' },
  { feature: 'SOCKS5 和 HTTP(s)' },
  { feature: '全球8个地点' },
  { feature: '旋转和静态 IP' },
  { feature: '<0.3 秒响应时间' },
  { feature: '列入白名单的 IP' },
];

const featuresDedicatedChina = [
  { feature: '40万个美国IP' },
  { feature: '无限线程和 GB' },
  { feature: 'SOCKS5 和 HTTP(s)' },
  { feature: '99.99% 正常运行时间' },
  { feature: '超过 1500 个子网' },
  { feature: 'IP 替换选项' },
];

export const featuresDcChina = {
  payGb: featuresDcPayGbChina,
  sharedPayIp: featuresDcPayIpChina,
  dedicatedPayIp: featuresDedicatedChina,
};
