import { GatsbyImg, IconClock } from '@smartproxy-web/ui';
import {
  AuthorBlock,
  BlogData,
  BlogInfo,
  BlogTitle,
  SmallBlogImageWrapper,
  Topic,
  Topics,
} from './styledComponents';
import { MostPopularPostProps } from './types';
import { GatsbyLink } from '../GatsbyLink';
import { normalizeTopicPath } from './utils';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { translations } from '../BlogPostIntro/constants';
import { useBuildContext } from '../../contexts';

export const MostPopularPost = ({
  image,
  blogTopics,
  title,
  blogAuthor,
  date,
  minutes,
  url,
  theme,
}: MostPopularPostProps) => {
  const { lang } = useBuildContext();
  const topicTrackingSelector = initBuildTrackingId({
    section: 'blog-parent',
  });
  const blogTrackingSelector = initBuildTrackingId({
    section: 'blog-parent',
    context: title.text,
  });
  const isTopicsAdded = blogTopics && blogTopics.length > 0;
  return (
    <BlogData>
      <SmallBlogImageWrapper
        to={url}
        data-tracker={blogTrackingSelector()}
        onClick={() => logPostHogEvent(blogTrackingSelector())}
      >
        <GatsbyImg image={image} />
      </SmallBlogImageWrapper>
      <div>
        {isTopicsAdded && (
          <Topics>
            {blogTopics.map((topic) => (
              <Topic
                data-tracker={topicTrackingSelector({
                  context: `topic-${topic}`,
                })}
                onClick={() =>
                  logPostHogEvent(
                    topicTrackingSelector({
                      context: `topic-${topic}`,
                    })
                  )
                }
                isDark={!theme}
                key={`${topic}-${Math.random()}`}
                to={`/blog?=${normalizeTopicPath(topic)}`}
              >
                {topic}
              </Topic>
            ))}
          </Topics>
        )}
        <BlogTitle
          isDark={!theme}
          data-tracker={blogTrackingSelector()}
          onClick={() => logPostHogEvent(blogTrackingSelector())}
        >
          <GatsbyLink to={url}>
            <h4>{title.text}</h4>
          </GatsbyLink>
          <AuthorBlock>
            <BlogInfo>
              <p>{blogAuthor[0]?.name.text}</p>
            </BlogInfo>
            <BlogInfo>
              <p>{date}</p>
            </BlogInfo>
            <BlogInfo>
              <IconClock />
              <p>
                {minutes} {translations.min_read[lang]}
              </p>
            </BlogInfo>
          </AuthorBlock>
        </BlogTitle>
      </div>
    </BlogData>
  );
};
