import { PRISMIC_VALUES, SOCIAL_BUTTON_TYPE } from 'constants/common';
import { Link } from 'components/GatsbyLink';
import { NewRichText } from 'components/PrismicRichText';
import { getComma, getTopicLabel } from 'features/ContentTags/utils';
import {
  IconFacebookSocial,
  IconLinkedinSocial,
  IconRedditSocial,
  IconTwitterSocial,
} from 'images';
import React from 'react';
import SocialButton from './SocialButton';
import {
  ButtonContainer,
  HideableAdditionalText,
  HideableTextContainer,
  StyledContainer,
  StyledTopicsLink,
  StyledTopicsText,
  Wrapper,
} from './styledComponents';
import { ISocialButtonsBarProps } from './types';
import { normalizeTopicPath } from '@smartproxy-web/features/src/components/BlogParent/utils';

export const SocialButtonsBar: React.FC<ISocialButtonsBarProps> = ({
  primary: {
    is_fb_visible: isFbVisible,
    is_twitter_visible: isTwitterVisible,
    is_reddit_visible: isRedditVisible,
    background,
    is_icons_centered: isCentered,
    is_linkedin_visible: isLinkedinVisible,
    text,
  },
  items,
  isV2,
}) => {
  const isTopicsVisible = !!items?.length;

  return (
    <Wrapper background={background} isV2={isV2}>
      <StyledContainer>
        <HideableTextContainer isVisible={isTopicsVisible}>
          <StyledTopicsText>{PRISMIC_VALUES.TOPICS}</StyledTopicsText>
          {items?.map(({ topic: { document } }, index) => (
            <Link
              key={index}
              to={`/blog?=${normalizeTopicPath(document.data.topic_label)}`}
            >
              <StyledTopicsLink>{getTopicLabel(document)}</StyledTopicsLink>
              {getComma(index, items.length)}
            </Link>
          ))}
        </HideableTextContainer>
        <ButtonContainer isCentered={isCentered}>
          <HideableAdditionalText isVisible={!!text}>
            <NewRichText text={text} />
          </HideableAdditionalText>
          <SocialButton
            isVisible={isTwitterVisible}
            type={SOCIAL_BUTTON_TYPE.TWITTER}
          >
            <IconTwitterSocial />
          </SocialButton>
          <SocialButton
            isVisible={isFbVisible}
            type={SOCIAL_BUTTON_TYPE.FACEBOOK}
          >
            <IconFacebookSocial />
          </SocialButton>
          <SocialButton
            isVisible={isRedditVisible}
            type={SOCIAL_BUTTON_TYPE.REDDIT}
          >
            <IconRedditSocial />
          </SocialButton>
          <SocialButton
            isVisible={isLinkedinVisible}
            type={SOCIAL_BUTTON_TYPE.LINKEDIN}
          >
            <IconLinkedinSocial />
          </SocialButton>
        </ButtonContainer>
      </StyledContainer>
    </Wrapper>
  );
};
