import { Product } from './products';

export const MOBILE_PROXIES_PLANS: Record<string, string> = {
  nano: '886547',
  micro: '886548',
  starter: '886549',
  regular: '886550',
  advanced: '886551',
  premium: '886552',
  professional: '886553',
};

export const SERP_SCRAPER_PLANS: Record<string, string> = {
  '3 day trial': '774220',
  lite: '811108',
  basic: '645033',
  standard: '811111',
  solid: '811110',
};

export const ECOMMERCE_SCRAPER_PLANS: Record<string, string> = {
  '3 day trial': '774221',
  lite: '773224',
  basic: '773223',
  standard: '811118',
  solid: '811116',
};

export const WEB_SCRAPER_PLANS: Record<string, string> = {
  '3 day trial': '774222',
  lite: '773981',
  basic: '773982',
  standard: '773983',
  solid: '773984',
  plus: '815330',
  delux: '815330',
  pro: '815330',
  guru: '815330',
};

export const NO_CODE_SCRAPER_PLANS: Record<string, string> = {
  '3 day trial': '774223',
  lite: '774033',
};

export const SOCIAL_MEDIA_SCRAPER_PLANS: Record<string, string> = {
  '3 day trial': '815488',
  lite: '815284',
  basic: '815328',
  standard: '815329',
  solid: '815330',
  plus: '815331',
  delux: '815332',
  pro: '815333',
  guru: '815334',
};

export const SITE_UNBLOCKER_PLANS: Record<string, string> = {
  nano: '885025', //per 1k req
  micro: '885026', //per 1k req
  starter: '885027', //per 1k req
  regular: '885028', //per 1k req
  advanced: '835601', //per GB
  premium: '835638', //per GB
  professional: '835704', //per GB
  enterprise: '835759', //per GB
};

export const ISP_PROXIES_PLANS: Record<string, string> = {
  nano: '832090',
  micro: '832091',
  starter: '832092',
  regular: '832093',
  advanced: '832094',
  premium: '832095',
  professional: '832096',
  enterprise: '832097',
};

export const RESIDENTIAL_PROXIES_PLANS: Record<string, string> = {
  nano: '873101',
  micro: '873102',
  starter: '873103',
  regular: '873104',
  advanced: '873105',
};

export const DATACENTER_PROXIES_PLANS: Record<string, string> = {
  nimble: '777240',
  foxy: '777241',
  clever: '777242',
  sharp: '777243',
};

export const DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ: Record<
  string,
  string
> = {
  teeny: '793262',
  tiny: '793265',
  mini: '793266',
  general: '793267',
  main: '793268',
};

export const DEDICATED_DATACENTER_PROXIES_PLANS: Record<string, string> = {
  teeny: '786665',
  tiny: '776918',
  mini: '776919',
  general: '776920',
  main: '776921',
};

export const RESI_TRIAL: Record<string, string> = {
  nano: '873113',
  micro: '873114',
  starter: '873115',
  regular: '873116',
  advanced: '873117',
};

export const DISP: Record<string, string> = {
  disp10: '861957',
  disp25: '861958',
  disp50: '862447',
  disp75: '862448',
  disp100: '862449',
  disp150: '862450',
};

export const planIds: Partial<Record<Product, Record<string, string>>> = {
  mobile: MOBILE_PROXIES_PLANS,
  ecommerce: ECOMMERCE_SCRAPER_PLANS,
  no_code: NO_CODE_SCRAPER_PLANS,
  serp: SERP_SCRAPER_PLANS,
  social_media: SOCIAL_MEDIA_SCRAPER_PLANS,
  web: WEB_SCRAPER_PLANS,
  site_unblocker: SITE_UNBLOCKER_PLANS,
  isp: ISP_PROXIES_PLANS,
  residential: RESIDENTIAL_PROXIES_PLANS,
  datacenter: DATACENTER_PROXIES_PLANS,
  dedicated_datacenter: DEDICATED_DATACENTER_PROXIES_PLANS,
  resi_trial: RESI_TRIAL,
  disp: DISP,
};
