import { RichText } from 'components/v2/RichText';
import { DynamicToggle } from 'components/v2/Toggle';
import { RESIDENTIAL_PRICING_VALUES, SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { useSplitTesting } from 'contexts/SplitTesting';
import { OfferCountdownStatus } from 'features/ResidentialPricingCards/OfferCountdownStatus';
import { useResiPromo } from 'features/ResidentialPricingCards/useResiPromo';
import { assertProduct, generateSectionsId } from 'features/utils';
import { IconManagerv2 } from 'images';
import React, { useState } from 'react';
import { themev2 } from 'theme-v2';
import { buildTrackingSelector } from 'utils/page';
import { PaymentMethods } from './components/PaymentMethods';
import Plans from './components/Plans';
import {
  HideableEnterpriseFeature,
  HideableSubtitle,
  PaymentMethodsSpacing,
  PricingCardsWrapper,
  Title,
  TopLabelComponent,
  ValidationTextComponent,
} from './styledComponents';
import { IAutomatedPricingCards } from './types';
import { excludePricingFeatures, getProductType } from './helpers';
import { Box } from '@smartproxy-web/ui';

export type AutomatedPricingToggleType = 'regular' | 'enterprise';

export const AutomatedPricingCards: React.FC<IAutomatedPricingCards> = ({
  primary: {
    is_limited_time_offer_visible: isLimitedTimeOfferVisible,
    title,
    subtitle,
    switch_regular_label: regularLabel,
    switch_enterprise_label: enterpriseLabel,
    enterprise_benefit: enterpriseBenefit,
    background_color: backgroundColor,
  },
  items,
}) => {
  const { type, tags } = usePageContext();
  const [isFirstTabActive, setFirstTabActive] = useState(true);
  const [isSecondTabActive, setSecondTabActive] = useState(false);

  const getBackgroundColor = (color) =>
    color && color.toLowerCase() === 'gray'
      ? themev2.colors.neutral.N02
      : themev2.colors.white.default;

  const setActiveTab = () => {
    setFirstTabActive((prevState) => !prevState);
    setSecondTabActive((prevState) => !prevState);
  };

  const regularPlans = items.filter(
    ({
      pricing_card: {
        document: {
          data: { is_enterprise: isEnterprise },
        },
      },
    }) => !isEnterprise
  );

  const enterprisePlans = items.filter(
    ({
      pricing_card: {
        document: {
          data: { is_enterprise: isEnterprise },
        },
      },
    }) => isEnterprise
  );
  const { variant } = useSplitTesting();
  const promo = useResiPromo(variant);
  const isPromoBannerVisible = !!promo.expiry;

  const normalizedRawGroupProduct = getProductType(
    items.filter(excludePricingFeatures)
  );
  const groupProduct = assertProduct(normalizedRawGroupProduct)
    ? normalizedRawGroupProduct
    : 'mixed';
  const showToggleButton = !!regularLabel || !!enterpriseLabel;

  return (
    <PricingCardsWrapper
      backgroundColor={getBackgroundColor(backgroundColor)}
      data-section-id={generateSectionsId(
        SECTION_NAMES.PRICING_CARDS,
        tags,
        type
      )}
      id={buildTrackingSelector({
        prefix: type,
        page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
        context: title.text,
      })}
    >
      {isPromoBannerVisible && isLimitedTimeOfferVisible && (
        <TopLabelComponent>
          <OfferCountdownStatus
            isGreen
            key={promo.expiry}
            expiry={promo.expiry}
            label={RESIDENTIAL_PRICING_VALUES.LABEL}
            isTimerVisible={promo.isTimerVisible}
          />
        </TopLabelComponent>
      )}
      {!!promo.description?.text && isLimitedTimeOfferVisible && (
        <ValidationTextComponent>
          <RichText field={promo.description} />
        </ValidationTextComponent>
      )}
      <Title
        isSubtitleAdded={!!subtitle.text}
        isToggleVisible={showToggleButton}
      >
        <RichText field={title} />
      </Title>
      <HideableSubtitle isVisible={!!subtitle.text}>
        <RichText field={subtitle} />
      </HideableSubtitle>
      {showToggleButton && (
        <Box
          sx={{
            marginBottom: { base: '32px', md: '60px', lg: '80px' },
          }}
        >
          <DynamicToggle
            tabs={[
              {
                key: 'regular',
                label: regularLabel,
              },
              {
                key: 'enterprise',
                label: enterpriseLabel,
              },
            ]}
            onSelect={() => setActiveTab()}
          />
          <HideableEnterpriseFeature
            isVisible={isSecondTabActive && !!enterpriseBenefit}
          >
            <IconManagerv2 />
            {enterpriseBenefit}
          </HideableEnterpriseFeature>
        </Box>
      )}
      <Plans isVisible={isFirstTabActive} plans={regularPlans} />
      <Plans isVisible={isSecondTabActive} plans={enterprisePlans} />
      <PaymentMethodsSpacing />
      <PaymentMethods product={groupProduct} />
    </PricingCardsWrapper>
  );
};
