import { getFeatureFlags, getFeatureFlag } from '@smartproxy/sp-tracker-js';
import { useEffect, useState } from 'react';
import {
  getFeatureFlagValue,
  runWithExponentialBackoff,
  trackExperimentImpresion,
} from '../utils';

let isFeatureFlagsInitialized = false;

export const useFeatureFlag = <T extends string | boolean>(
  key: string,
  defaultValue: T
) => {
  const [value, setValue] = useState<string | boolean>(defaultValue);

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      if (!isFeatureFlagsInitialized) {
        await runWithExponentialBackoff(getFeatureFlags)
          .then(() => {
            isFeatureFlagsInitialized = true;
          })
          .catch((err: unknown) => {
            console.error("couldn't fetch feature flags", err);
          });
      }
      setValue(getFeatureFlagValue(key));
    };

    fetchFeatureFlag();
  }, []);

  return value;
};

export const useABTest = <T extends string | boolean>(
  key: string,
  defaultValue: T
) => {
  const [value, setValue] = useState<string | boolean>(defaultValue);
  const [isGettingABTest, setIsGettingABTest] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    let attempts = 0;

    const fetchABTestValue = async () => {
      timeoutId = setInterval(() => {
        const abTestValue = getFeatureFlag(key);

        if (attempts === 3) {
          setIsGettingABTest(false);
          timeoutId && clearInterval(timeoutId);
          return;
        }
        attempts++;

        if (abTestValue === undefined) {
          return;
        }

        setIsGettingABTest(false);
        timeoutId && clearInterval(timeoutId);
        trackExperimentImpresion(key, abTestValue);
        if (abTestValue !== defaultValue) {
          setValue(abTestValue);
        }
      }, 750);
    };

    fetchABTestValue();

    return () => {
      setIsGettingABTest(false);
      timeoutId && clearInterval(timeoutId);
    };
  }, []);

  return { abValue: value, isGettingABTest };
};

export const useAsyncFeatureFlag = (featureFlag: string) => {
  const [isFetched, setIsFetched] = useState(false);
  const [fetchedFeatureFlag, setFetchedFeatureFlag] = useState(false);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const flags = await getFeatureFlags();
      console.log('flags', flags);

      setFetchedFeatureFlag(flags?.includes(featureFlag));
      setIsFetched(true);
    };

    fetchFeatureFlags();
  }, [featureFlag]);

  return { isFetched, flagExists: fetchedFeatureFlag };
};
